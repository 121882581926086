<template lang="pug">
.call-for-register
  span.main Еще больше закупок для Вас в системе ТендерМонитор
  span.secondary Все тендеры и госзакупки России {{ getYear() }}
  span.secondary Сервис поиска тендеров по № 44-ФЗ, № 223-ФЗ, ПП-615
  ui-button.register-button(@click="showDialog('registration')") Попробуй 7 дней бесплатно
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useLandingLogin } from "@/use/auth/useLandingLogin";
import { getYear } from "@/utils/formatter/dateFormatter";
import UiButton from "@/components/ui/button/UiButton.vue";

export default defineComponent({
  name: "CallForRegistration",
  methods: {
    getYear,
  },
  components: {
    UiButton,
  },
  setup() {
    const { showDialog } = useLandingLogin();

    return {
      showDialog,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/landing/button';

.call-for-register {
  max-width: 1140px;
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 18px;
  padding: 40px 32px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;

  .main {
    color: #409eff;
    font-size: 28px;
    font-weight: bold;
  }

  .secondary {
    color: #324059;
    font-size: 18px;
    font-weight: 300;
  }
}

@media(max-width: 640px) {
  .call-for-register {
    .main {
      font-size: 20px;
    }
    .secondary {
      font-size: 14px;
    }
  }
}
</style>
