<template lang="pug">
.no-data(v-if="!results") Данные отсутствуют
.lot-detail-table(v-else)
  .header.grid-layout.side-padding
    .td Участник
    .td Предложенная цена
    .td.full-view % снижения
    .td.full-view Решение комиссии
  .table-row.grid-layout.side-padding(
    v-for="(supplier, index) of results"
    :key="index"
  )
    .td.participant(:class="supplier.appRating === 1 && 'winner'")
      .rating
        ui-icon(v-if="supplier.appRating === 1" :icon="UiIconNames.Landing_Trophy" :size="18")
        span(v-else) {{ supplier.appRating }}
      .column
        analytic-link(v-if="supplier.supplier" tab="suppliers" :company="supplier.supplier")
        div(v-else) Участник неизвестен
        span.adaptive-view.decline(v-if="supplier.decisionCommission") {{ supplier.decisionCommission }}
    .td.column
      span {{ costFormatterExtended(supplier.cost.original) }}
      span.adaptive-view.decline(v-if="supplier.decline") ↓ {{ supplier.decline || 0 }} %
    .td.full-view {{ supplier.decline }}
    .td.full-view {{ supplier.decisionCommission }}
</template>

<script lang="ts">
import { defineComponent, } from "vue";
import { costFormatterExtended } from "@/utils/formatter/costFormatter";
import type { PropType } from "vue";
import type { AuctionResultsLDI, } from "@/stores/search/LotItemInterface";
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import AnalyticLink from "@/components/ui/links/AnalyticLink.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "9_Results",
  methods: {
    costFormatterExtended,
  },
  components: {
    UiIcon,
    AnalyticLink,
  },
  props: {
    results: {
      type: Array as PropType<AuctionResultsLDI[]>,
      default: () => ([]),
    }
  },
  setup() {
    return {
      UiIconNames,
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/table/lotDetail';
@import '@/assets/styles/mixin/fonts';

.rating {
  display: flex;
  width: 24px;
  flex-shrink: 0;

  span {
    padding-left: 4px;
  }
}

.adaptive-view {
  display: none;
}

.column {
  display: flex;
  flex-flow: column;
  gap: 4px;
}

.decline {
  color: var(--main-placeholder-color);
  font-size: var(--detail-table--size--info);
}

.participant {
  display: flex;
  flex-flow: row;
  gap: 8px;
}

.grid-layout {
  display: grid;
  grid-template-columns: 5fr minmax(140px, 2fr) minmax(100px, 1fr) minmax(172px, 2fr);
}

.winner {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--main-warning-color);

  a {
    color: var(--main-warning-color);
  }
}

@media (max-width: 860px) {
  .adaptive-view {
    display: flex;
  }

  .full-view {
    display: none;
  }

  .grid-layout {
    grid-template-columns: 5fr minmax(120px, 2fr) 0 0;
  }
}

@media(max-width: 380px) {
  .lot-detail-table {
    overflow-wrap: anywhere;
  }
}
</style>
